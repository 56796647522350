import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ErrorIcon from "@material-ui/icons/Error"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

// page styles
import formCompleteStyle from "assets/jss/material-kit-pro-react/views/registrationOnlinePageSections/formCompleteStyle.jsx"
const useStyles = makeStyles(formCompleteStyle)

export default function SectionFormComplete(props) {
  const { handleNext, paymentName, submitError } = props

  const handleClick = () => {
    handleNext()
  }

  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} lg={8}>
            {!submitError ? (
              <>
                <h2 className={classes.title}>Registration Complete</h2>
                <CheckCircleIcon className={classes.paymentSuccessIcon} />
                <h3 className={classes.paymentTitle}>
                  Thank You{` ${paymentName}`}!
                </h3>
                <p>
                  <b>Your Registration is Complete</b>
                  <br />
                  You will be recieving a follow-up email shortly with the event
                  rules and cook's letter. If you do not recieve this email,
                  feel free to download them directly from the{" "}
                  <Link to="/register">register page</Link>.
                  <br />
                </p>
                <p>
                  <b>Questions?</b> <br />
                </p>
                <ul>
                  <li>
                    Contact:{" "}
                    <a href="mailto:cookoff@chilimania.com">
                      cookoff@chilimania.com
                    </a>{" "}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h2 className={classes.title}>Registration Failed</h2>
                <ErrorIcon className={classes.paymentErrorIcon} />
                <h3 className={classes.paymentTitle}>
                  We're Sorry{` ${paymentName}`}.
                </h3>
                <p>
                  <b>
                    We were unable to collect your registration information.
                  </b>
                  <br />
                </p>
                <p>
                  <b>Your Payment Was Accepted</b>
                </p>
                <p>
                  We're sorry, we are unable to submit your registration form.
                  Feel free to email your registration details or use the manual
                  registration form available for download on the{" "}
                  <Link to="/register">register page</Link>. To futher discuss
                  registration details, refunds, or issues with payment, please
                  use the contact email listed below.
                </p>
                <ul>
                  <li>
                    Contact:{" "}
                    <a href="mailto:cookoff@chilimania.com">
                      cookoff@chilimania.com
                    </a>{" "}
                  </li>
                </ul>
              </>
            )}
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.submitButton}>
                <Link to="/register">
                  <Button color="primary" onClick={handleClick}>
                    Return to Website
                    <ChevronRight />
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

SectionFormComplete.propTypes = {
  handleNext: PropTypes.func,
  paymentName: PropTypes.string,
  submitError: PropTypes.bool,
}
