import {
  container,
  dangerColor,
  description,
  section,
  successColor,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const formCompleteStyle = {
  closed: {
    ...description,
    ...textCenter,
    color: dangerColor[0],
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "0",
    justifyContent: "center",
    color: whiteColor,
    "& p": {
      ...description,
    },
    "& li": {
      ...description,
    },
  },
  formItem: {
    display: "flex",
    justifyContent: "center",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  paymentErrorIcon: {
    color: dangerColor[0],
    fontSize: "3rem",
    marginTop: "30px",
  },
  paymentSuccessIcon: {
    color: successColor[0],
    fontSize: "3rem",
    marginTop: "30px",
  },
  paymentTitle: {
    ...title,
    marginTop: "0",
  },
  registerButton: {
    margin: "0 auto",
  },
  section: {
    ...section,
    padding: "0px",
  },
  submitButton: {
    ...textCenter,
    marginTop: "1rem",
  },
  subtitle: {
    ...description,
    ...textCenter,
    margin: "0 auto 30px auto",
  },
  title: {
    ...title,
    ...textCenter,
  },
}

export default formCompleteStyle
